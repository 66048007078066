<template>
  <div class="white">
    <nuxt-link
      class="text-h4 text-md-h3 text-decoration-none grey-darken-4--text"
      :to="url"
    >
      <base-image
        v-if="media"
        :media="media"
        :height="imageHeight"
        :alt="title"
        :init-height="heightNumber"
        loading="lazy"
        style="width: 100%; object-fit: cover"
      />
    </nuxt-link>
    <div class="px-3 py-4 d-flex flex-column">
      <nuxt-link
        class="
          mx-auto
          text-overline text-uppercase
          grey-darken-4--text
          text-decoration-none
        "
        :to="categoryUrl"
      >
        {{ categoryName }}
      </nuxt-link>
      <hr
        class="grey-darken-4 mt-2 mb-3 mx-auto d-inline-block"
        style="width: 20px; border-bottom: none"
      />
      <nuxt-link
        class="text-h4 text-md-h3 text-decoration-none grey-darken-4--text"
        :to="url"
        >{{ title }}
      </nuxt-link>
      <p class="text-body-3 mt-3 mb-0">
        {{ teaser | truncate(200) }}
      </p>
    </div>
  </div>
</template>

<script>
import { getBlogMainCategory, getBlogUrl, getCategoryUrl } from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'BlogSimpleCard',
  components: { BaseImage },
  props: {
    blog: {
      type: Object,
      default: () => ({}),
    },
    imageHeight: {
      type: String,
      default: '186',
    },
  },
  computed: {
    heightNumber() {
      return (
        this.imageHeight && parseInt(this.imageHeight.replace('px', '').trim())
      )
    },
    url() {
      return getBlogUrl(this.blog)
    },
    media() {
      return this.blog?.media
    },
    title() {
      return this.blog.translated.title
    },
    teaser() {
      return this.blog.translated.teaser
    },
    mainCategory() {
      return getBlogMainCategory(this.blog)
    },
    categoryName() {
      return this.mainCategory.translated.name
    },
    categoryUrl() {
      return getCategoryUrl(this.mainCategory)
    },
    publishedDate() {
      return this.$d(new Date(this.blog.publishedAt), 'short')
    },
  },
}
</script>
