<script>
import BlogSlider from '~/components/blog/BlogSlider'
import { getCmsSlotConfigProperty, getCmsSlotDataElements } from '~/helpers'
export default {
  name: 'CmsElementBsBlogSlider',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h) {
    const title = getCmsSlotConfigProperty(this.content, 'title')

    return h(
      'div',
      {
        staticClass:
          'apricot-pastel-light px-3 px-md-10 py-4 py-md-10 mx-n3 mx-sm-0',
      },
      [
        title
          ? h(
              'div',
              {
                staticClass:
                  'text-h2 text-md-h1 text-center text-uppercase mb-7 mt-3 mb-md-10 mt-md-n1',
              },
              title
            )
          : undefined,
        h(BlogSlider, {
          props: {
            blogs: getCmsSlotDataElements(this.content),
          },
        }),
      ]
    )
  },
}
</script>
