<template>
  <base-slider>
    <base-slider-item v-for="blog in blogs" :key="blog.id">
      <blog-simple-card :blog="blog" style="width: 280px"></blog-simple-card>
    </base-slider-item>
  </base-slider>
</template>

<script>
import BaseSlider from '~/components/base/slider/BaseSlider'
import BaseSliderItem from '~/components/base/slider/BaseSliderItem'
import BlogSimpleCard from '~/components/blog/BlogSimpleCard'

export default {
  name: 'BlogSlider',
  components: {
    BlogSimpleCard,
    BaseSliderItem,
    BaseSlider,
  },
  props: {
    blogs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
